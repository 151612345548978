class App {
  toggleMenus = document.querySelectorAll('.toggle-menu') as NodeListOf<HTMLElement>;
  menu = (document.querySelector('#responsive-menu') as HTMLElement).classList;

	constructor() {
	}

  start() {
    this.navigation()
    this.mediaVideo()
    this.setFloatingButtons()
    this.ofuscateButtons()
  }

  // Ofuscated buttons with base64 encoded url
	ofuscateButtons() {
		const buttons = document.querySelectorAll('.-ufo')
		buttons.forEach((button) => {
			button.addEventListener('click', (e) => {
				e.preventDefault()
				const encodedUrl = button.getAttribute('href')
        const baseUri = window.location.origin + '/'
				const removedPrefix = encodedUrl.replace(baseUri, '')
				const rawUrl = atob(removedPrefix).replace('%2F', '/')
				// Transform encoded url with % characters to normal url
				const decodedUrl = decodeURIComponent(rawUrl)
				const win = window.open(decodedUrl, '_blank')
				win.focus()
			})
		})
	}

	navigation() {
		this.toggleMenus.forEach(toggler => {
			toggler.addEventListener('click', () => {
				const timeout = this.menu.contains('open') ? 500 : 0
				setTimeout(() => this.menu.toggle('hidden'), timeout);
				this.menu.toggle('open')
			})
		})
	}

	mediaVideo() {
    const videos = document.querySelectorAll('.media-video') as NodeListOf<HTMLVideoElement>
		videos.forEach((v: HTMLVideoElement) => {
			try {
				v.muted = true
				v.load()
				v.play()
			} catch(e) {
				alert('Cannot play' + v.src + ': ' + e)
				v.controls = true
			}
		})
	}

	setFloatingButtons() {
		document.querySelectorAll('.ofuscate')
		.forEach(e => e.addEventListener('click', () => {
			const target = e.getAttribute('data-target')
			if(target) {
				const a = document.createElement('a')
				a.href = atob(target)
				a.target = '_blank'
				a.click()
			}
		}))
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const app = new App()
	app.start()

	const main = document.querySelector('main') as HTMLElement
	const script = document.createElement('script')
  let root = main.getAttribute('data-root');

  var route = ''
  if(main.getAttribute('child') === 'true' || window.location.pathname.includes('/en')){
    route = root +`/assets/js/${main.id}.min.js`
  }else{
    route = root + `/assets/js/${main.id}.min.js`
  }

	script.src = route
	script.async = true
	script.type = 'module'
	document.body.appendChild(script)

	script.onerror = () => {
		console.error(`Error loading ${route}`)
	}
})
